import { Component, inject } from '@angular/core';
import { LoggerService } from '@app/services/logger.service';
import { Permission } from '@app/services/perm-helper.service';
import { SignalRFacade } from '@ra-state';
import { AuthService } from '@rockwell-automation-inc/service';
import { tap } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  readonly Permission = Permission;
  emailInputValue: string = '';
  tenantId: string = '';
  userInfo$ = this.authService.factoryTalkUser$;

  logger = inject(LoggerService).withContext(HomeComponent);

  isHubConnected$ = this.signalRFacade.isHubConnected$.pipe(
    tap((connState) => this.logger.log('Hub Connection State: ', connState)),
  );

  constructor(
    private readonly authService: AuthService,
    private signalRFacade: SignalRFacade,
  ) {}
}
